@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;600&display=swap');

$primary-font: 'Poppins', sans-serif;

html {
    font-size: 62.5%;
}

body {
    margin: 0;
    box-sizing: border-box;
    font-family: $primary-font;
    background-color: #202430;
    color: white;
}

.color--primary {
    color: #EF6461;
}

.underline--primary {
    text-decoration: underline;
    text-decoration-color: #EF6461;
    text-underline-offset: 6px;
}

.flex {
    display: flex;
}

.flex--50 {
    flex: 50%;
}

.flex--wrap {
    flex-wrap: wrap;
}

.justify-center {
    justify-content: center;
}

.flex--column {
    flex-direction: column;
}

.justify-space-between {
    justify-content: space-between;
}

.align-self-center {
    align-self: center;
}

.color-blue {
    color: #022151;
}

.flex-end {
    justify-content: flex-end;
}

.section {
    &__title {
        color: #EF6461;
        text-transform: uppercase;
        font-size: 4rem;
        font-weight: bold;
        margin-bottom: 15px;
    }

    &__subtitle {
        font-size: 3rem;
        font-weight: bold;
        text-decoration: underline;
        text-decoration-color: #EF6461;
        text-underline-offset: 10px;
        margin: 15px 0 0;

        @media screen and (max-width: 500px) {
            font-size: 2rem;
        }
    }

    &__bordered-content {
        border-left: 2px solid #EF6461;
        padding-left: 30px;
        margin-left: 15px;
        color: white;
        font-size: 2.5rem;
        font-weight: bold;
        line-height: 50px;

        @media screen and (max-width: 500px) {
            font-size: 2rem;
        }
    }
}

.container {
    max-width: 1400px;
    margin: 0 auto;
}

@import './projects.scss';
@import './project-detail.scss';
@import './form.scss';

// new
@import './technologies.scss';
@import './menu.scss';
@import './works.scss';
@import './presentation.scss';
@import './education.scss';
@import './about.scss';
@import './skills.scss';