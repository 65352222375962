.presentation {
    margin-top: 230px;

    @media screen and (max-width: 500px) {
        margin-top: 30px;
    }

    &__title {
        font-size: 13rem;
        text-align: center;

        @media screen and (max-width: 500px) {
            font-size: 8rem;
            line-height: 1.4;
        }
    }

    &__subtitle {
        font-size: 8rem;
        font-weight: 100;
        text-align: center;

        @media screen and (max-width: 500px) {
            font-size: 4rem;
            margin-top: 50px;
            line-height: 1.2;
        }
    }

    .bitmoji {
        width: 180px;
        height: 180px;
        margin: 0 0 -40px 20px;
    }

    .socials {
        a {
            margin-top: 40px;

            svg {
                color: white;
                transition: color 0.4s ease-in-out;
            }

            &:hover {
                svg {
                    color: #EF6461;
                    transition: color 0.4s ease-in-out;
                }
            }

            &:nth-child(2) {
                margin-left: 105px;
                margin-right: 105px;

                @media screen and (max-width: 500px) {
                    margin-left: 50px;
                    margin-right: 50px;
                }
            }
        }
    }
}
