.technologies {
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    max-width: 360px;
    padding: 25px 0;

    margin: 0 20px;


    &__title {
        padding-left: 10%;
        font-weight: bold;
        font-size: 24px;
    }

    &__skills {
        padding-left: 10%;
        margin-top: 20px;

        .skill {
            margin-bottom: 10px;

            img {
                width: 15px;
                height: 25px;
                margin-right: 5px;
            }

            div:first-child {
                font-weight: bold;
                color: #022151;
            }

            &__detail {
                margin-left: 20px;
                font-size: 14px;
            }
        }
    }
}
