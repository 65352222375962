.projects {
    margin-top: 30px;
    flex-wrap: wrap;

    &-title {
        font-weight: bold;
        font-size: 3rem;
        color: #8DCC75;
        margin-bottom: 20px;
    }

    &-list {
        margin-top: 30px;
        flex-wrap: wrap;
        justify-content: space-evenly;


        @media screen and (max-width: 1380px) {
            justify-content: center;
        }

        @media screen and (max-width: 550px) {
            flex-direction: column;
            align-items: center;
        }
    }

    .project {
        background-color: #262A34;
        border-radius: 20px;
        margin-bottom: 50px;
        max-width: 600px;

        @media screen and (max-width: 550px) {
            width: 90%;
            margin: 0 0 50px;
        }

        &:last-child {
            margin-right: 0;
        }

        &--text {
            padding: 0 30px;

            &--header {
                margin-top: 10px;
            }

            &--title {
                font-size: 2.5rem;
            }

            &--description {
                font-size: 1.6rem;
                font-weight: 100;
                margin-bottom: 12px;
                max-width: 600px;

                @media screen and (max-width: 720px) {
                    font-size: 1.6rem;
                    width: 100%;
                }
            }

            &-technologies {
               color: #EF6461;
               font-weight: 500;
               font-size: 1.6rem;
            }

            &--date {
                margin-bottom: 10px;
                font-weight: 400;
                font-size: 1.6rem;
            }

            .btn-see {
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                color: white;
                background-color: #EF6461;
                text-decoration: none;
                width: 120px;
                height: 40px;
                font-size: 1.4rem;
                float: right;
                border-radius: 20px;
                margin-bottom: 12px;

                @media screen and (max-width: 550px) {
                    margin-bottom: 20px;
                }
            }
        }

        &--picture {
            img {
                max-width: 600px;
                border-radius: 10px 10px 0 0;

                @media screen and (max-width: 1380px) {
                    width: 100%;
                }

                @media screen and (max-width: 550px) {
                    display: none;
                }
            }
        }

    }
}
