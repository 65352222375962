.skills {
  &__content {
    margin-left: 45px;
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 500px) {
      margin-left: 20px;
    }

    ul {
      margin-top: 30px;
      padding-left: 0;

      li {
        list-style: none;
        font-size: 2rem;
        margin: 5px 0;

        @media screen and (max-width: 500px) {
          font-size: 2rem;
        }
      }
    }
  }

  &__list {
    @media screen and (max-width: 500px) {
      margin: 0 20px;
    }
  }

  &__list:not(:last-child) {
    margin-right: 150px;

    @media screen and (max-width: 500px) {
      margin: 0 20px;
    }
  }
}
