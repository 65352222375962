.menu {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;

    &__item {
        font-weight: bold;
        visibility: hidden;
        font-size: 3rem;
        color: #8DCC75;

        a {
            text-decoration: none;
            color: initial;
        }

        img {
            width: 20px;
            height: 30px;
            margin-right: 5px;
        }

        &--active {
            visibility: visible;
        }
    }
}
