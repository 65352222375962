.project-detail {
    max-width: 1400px;
    margin: 0 4%;

    &__title {
        margin-top: 45px;
        font-size: 6rem;
        color: #EF6461;
        font-weight: bold;
        text-align: center;
    }

    &__date {
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 60px;
    }

    .section__title {
        font-size: 3rem;
    }

    .section__bordered-content {
        font-size: 1.8rem;
        line-height: 2;
    }

    .img-fluid {
        width: 100%;
        height: auto;
        object-fit: cover;
        align-items: center;
        display: flex;
    }

    .img__container {
        max-width: 800px;
        display: flex;
        justify-content: center;
        margin: auto;
    }

    &__content {
        img {
            margin-top: 50px;
        }

        .content__list {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 100px;

            div {
                flex-wrap: wrap;
                align-items: center;

                > div {
                    max-width: 500px;
                }

                > * {
                    margin: 10px 50px;
                }
            }
        }
    }
}
