.education {
    &-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: 50px;

        @media screen and (max-width: 1050px) {
            justify-content: center;
            flex-direction: row;
            margin-left: 0;
            flex: 1 80%;
        }


        &::-webkit-scrollbar {
            display: none;
        }

        .education {
            display: flex;
            flex-direction: column;
            box-shadow: 0 0 4px 1px rgba(32, 36, 48, 0.6);
            background-color: #262A34;
            backdrop-filter: blur(6px) saturate(100%);
            -webkit-backdrop-filter: blur(6px) saturate(100%);
            background-color: rgba(255, 255, 255, 0.1);
            border: 1px solid rgba(255, 255, 255, 0.125);
            border-radius: 20px;
            padding: 0 26px;
            margin: 0 0 50px 0;
            flex-shrink: 0;
            min-height: 150px;

            @media screen and (max-width: 550px) {
                width: 90%;
                padding: 0 10px;
                margin: 0 0 50px 0;
            }

            &__title {
                font-size: 2rem;
                font-weight: bold;

                @media screen and (max-width: 500px) {
                    font-size: 1.8rem;
                }

                &:first-child {
                    margin-top: 30px;
                }
            }

            &__date {
                margin: 2px 0 10px;
                font-size: 1.6rem;
                font-weight: 400;
                color: #ffffff;

                @media screen and (max-width: 500px) {
                    font-size: 1.4rem;
                }
            }

            &__description {
                width: 435px;
                font-size: 1.4rem;
                font-weight: 400;

                @media screen and (max-width: 500px) {
                    font-size: 1.2rem;
                }

                @media screen and (max-width: 550px) {
                    width: 80%;
                    margin-bottom: 20px;
                }
            }

            &__technologies {
                font-size: 1.4rem;
                font-weight: 100;
                margin: 15px 0;
                color: #EF6461;

                @media screen and (max-width: 500px) {
                    font-size: 1.2rem;
                }
            }
        }
    }
}
